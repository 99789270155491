<!-- @format -->

<template>
  <b-card class="mb-3" header-class="d-flex justify-content-between align-items-center">
    <!-- <b-form-select v-model="pageSize" :options="options" /> -->
    <b-row>
      <b-col md="2" class="mb-3">
        <div class="d-flex" style="align-items: center">
          <span style="width: 60px; display: flex">Page:</span>
          <b-form-select v-model="pageSize" :options="options" />
        </div>
      </b-col>
      <!-- 时间区段选择 -->
      <b-col md="2" class="mb-3 d-flex">
        <v-select
          v-model="year"
          :clearable="true"
          :options="years"
          :reduce="option => option.value"
          placeholder="Year"
          style="width:100%;"
        />
      </b-col>
      <b-col md="2" class="mb-3 d-flex">
        <v-select
          v-model="month"
          :clearable="true"
          :options="months"
          :reduce="option => option.value"
          placeholder="Month"
          style="width:100%;"
          :disabled="!year"
        />
      </b-col>

      <!--      搜索id
      <b-col cols="7" class="d-flex">
        <div class="mr-1">
          <b-form-input v-model="id" class="d-inline-block" placeholder="Member id" />
        </div>
        <div class="mr-1">
          <b-form-input v-model="real_name" class="d-inline-block" placeholder="Member name" />
        </div>
        <b-button
          @click="fetchData"
          variant="primary"
        >
          Go
        </b-button>-->
      </b-col>
    </b-row>
    <b-tabs content-class="mt-3" v-model="tabIndex" @input="chan">
      <b-tab title="Personal TP">
        <b-table :fields="itemsPerson" :items="itemsPersonItem" empty-text="No data" show-empty>
          <template #cell(active_tp)="data">
            <div class="font-weight-bold"> {{ data.value }}</div>
          </template>
        </b-table>
      </b-tab>
      <b-tab title="Baseshop TP">
        <b-table :fields="itemsPerson2" :items="itemsPersonItem" empty-text="No data" show-empty>
          <template #cell(active_tp)="data">
            <div class="font-weight-bold" @click="getActiveTeamTp(data)"> {{ data.value }}</div>
          </template>
        </b-table>
      </b-tab>
      <b-tab title="Superbase TP">
        <b-table :fields="itemsPerson" :items="itemsPersonItem" empty-text="No data" show-empty>
          <template #cell(active_tp)="data">
            <div class="font-weight-bold" @click="getActiveTeamTp(data)"> {{ data.value }}</div>
          </template>
        </b-table>
      </b-tab>
      <b-tab title="Infinity TP">
        <b-table :fields="itemsPerson" :items="itemsPersonItem" empty-text="No data" show-empty>
          <template #cell(active_tp)="data">
            <div class="font-weight-bold" @click="getActiveTeamTp(data)"> {{ data.value }}</div>
          </template>
        </b-table>
      </b-tab>
    </b-tabs>
    <b-pagination v-model="currentPage" :total-rows="total" :per-page="pageSize" aria-controls="my-table" class="my-0" align="center" />
    <b-modal
      id="getActiveTeamTp"
      ref="modal"
      title="TP查看"
      class="tp-modal"
    >
      <b-table :fields="getTeamTpFiele" :items="getTeamTp" empty-text="No data" show-empty />

    </b-modal>
  </b-card>
</template>

<script>
import { BTabs, BTab, BCard, BTable, BFormSelect, BPagination, BButton } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { getCurrentInstance, nextTick, reactive, ref, watch } from '@vue/composition-api'
import axiosIns from '@/libs/axios'

export default {
  components: {
    BTabs,
    BTab,
    BTable,
    BCard,
    BFormSelect,
    BPagination,
    BButton,
    vSelect
  },
  setup() {
    const years = [
      { value: '2022', label: '2022' },
      { value: '2023', label: '2023' },
      { value: '2024', label: '2024' }
    ]
    const months = [
      { value: '', label: 'ALL' },
      { value: '1', label: '1' },
      { value: '2', label: '2' },
      { value: '3', label: '3' },
      { value: '4', label: '4' },
      { value: '5', label: '5' },
      { value: '6', label: '6' },
      { value: '7', label: '7' },
      { value: '8', label: '8' },
      { value: '9', label: '9' },
      { value: '10', label: '10' },
      { value: '11', label: '11' },
      { value: '12', label: '12' },
    ]

    const { proxy } = getCurrentInstance()
    const itemsPerson = [
      {
        key: 'key',
        label: 'ID',
      },
      {
        key: 'real_name',
        label: 'Agent',
      },
      {
        key: 'active_tp',
        label: 'Inforce Tp',
        formatter: value => `${String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
      },
      {
        key: 'pid',
        label: 'Sponsor',
      },
      {
        key: 'parent_name',
        label: 'Sponsor Agent',
      },
    ]
    const itemsPerson2 = [
      {
        key: 'user_id',
        label: 'ID',
      },
      {
        key: 'real_name',
        label: 'Leader',
      },
      {
        key: 'active_tp',
        label: 'Inforce Tp',
        formatter: value => `${String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
      },
      {
        key: 'pid',
        label: 'Sponsor',
      },
      {
        key: 'parent_name',
        label: 'Sponsor Agent',
      },
    ]

    const getTeamTpFiele = [
      {
        key: 'id',
        label: 'ID',
      },
      {
        key: 'real_name',
        label: 'Agent',
      },
      {
        key: 'tp',
        label: 'TP',
      },
    ]

    const itemsPersonItem = ref([])

    const normalTeamTp = [
      {
        key: 'id',
        label: 'ID',
      },
      {
        key: 'name',
        label: 'Agent',
      },
      {
        key: 'tp',
        label: 'TP',
      },
      {
        key: 'action',
        label: 'Edit',
      },
    ]

    const superTeamTp = [
      {
        key: 'id',
        label: 'ID',
      },
      {
        key: 'name',
        label: 'Agent',
      },
      {
        key: 'tp',
        label: 'TP',
      },
      {
        key: 'action',
        label: 'Edit',
      },
    ]

    const travTeamTp = [
      {
        key: 'id',
        label: 'ID',
      },
      {
        key: 'name',
        label: 'Agent',
      },
      {
        key: 'tp',
        label: 'TP',
      },
      {
        key: 'action',
        label: 'Edit',
      },
    ]

    const tabs = reactive([
      { title: 'Personal TP', content: 'Tab 1 content' },
      { title: 'Baseshop TP', content: 'Tab 2 content' },
      { title: 'Superbase TP', content: 'Tab 3 content' },
      { title: 'Infinity TP', content: 'Tab 3 content' },
    ])

    const config = {
      wrap: true, // set wrap to true only when using 'input-group'
      altInput: true,
      dateFormat: 'Y-m',
    }

    const pageSize = ref(200)
    const currentPage = ref(1)
    const total = ref(0)
    const options = [
      { value: 10, text: '10' },
      { value: 20, text: '20' },
      { value: 50, text: '50' },
      { value: 100, text: '100' },
    ]

    // 四个表格的数据,四个方法调用
    const year = ref('')
    const month = ref('')
    const id = ref('')
    const real_name = ref('')
    const cate_id = ref('')
    const fetchData = async () => {
      nextTick(() => {
        axiosIns
        // .get('order/tp-all', {
          .get('OrderPer/tp', {
          // .get('order/click-get-tp', {
            params: {
              cate_id: cate_id.value,
              year: year.value,
              month: month.value,
              page_size: pageSize.value,
              page: currentPage.value,
              id: id.value,
              real_name: real_name.value,
            },
          })
          .then(res => {
            const list = res.data?.list.map(item => (item[0] ? item[0] : item))
            itemsPersonItem.value = list
            total.value = res.data?.total
          })
      })
    }

    watch([year], () => {
      if (!year.value) {
        month.value = ''
      }
      if (year.value && !month.value) {
        month.value = ''
      }
      if (!year.value) {
        month.value = ''
      }
    })

    watch([year, month], () => {
  fetchData()
})
    // watch([year, month, pageSize, id, real_name, currentPage], () => {
    //   fetchData()
    // })

    const getTeamTp = ref([])
    const getActiveTeamTp = data => {
      // getTeamTp.value = []
      // axiosIns.get(`order//click-get-tp?user_id=${data.item.user_id}&cate=${cate_id.value}`).then(res => {
      //   getTeamTp.value = res.data
      //   proxy.$bvModal.show('getActiveTeamTp')
      // })
      // proxy.$bvModal.show('getActiveTeamTp')
    }

    const chan = val => {
      cate_id.value = val + 1
      fetchData()
    }
    const tabIndex = ref(0)
    if(proxy.$route.query.tabIndex){
      tabIndex.value = parseInt(proxy.$route.query.tabIndex)
    }
    chan(tabIndex.value)
    return {
      cate_id,
      currentPage,
      total,
      config,
      fetchData,
      options,
      id,
      real_name,
      year,
      month,
      tabs,
      chan,
      pageSize,

      itemsPerson,
      itemsPersonItem,
      itemsPerson2,
      getActiveTeamTp,
      getTeamTp,
      getTeamTpFiele,

      normalTeamTp,
      superTeamTp,
      travTeamTp,

      years,
      months,
      tabIndex,
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (to?.query?.name) {
        vm.real_name = to.query.name
      }
    })
  },
}
</script>

<style lang="scss" scoped>
#getActiveTeamTp {
  .modal-body {
    max-height: 82vh;
    overflow: auto;
  }
}
.tab-pane {
  overflow: auto;
}
</style>
